import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    cantonList: [],
    NWPCategory: [],
    NWPEligible: [],
    NWPStatus: [],
    NWPType: [],
    YesNo: [],
    UsedEvaluationSoftwareNWP: [],
    StadiumSportfield: [],
    TimeMeasure: [],
    ContactAddressType: [],
    Language: [],
    CorrespondenceLanguage: [],
    NwpDisciplineCategory: [],
    YoungestCategory: [],
    Qualificationsround: [],
    NwpHomologation: [],
    Sex: [],
    EmailType: [],
    MedalType: [],
    NWPQualificationForNextStage: [],
    NWPMaterialMedalsInterest: [],
  }),
  getters: {
    getCantonList: (state) => {
      return state.cantonList;
    },
    getCanton: (state) => (id) => {
      return state.cantonList.find((cantonList) => cantonList.id === id);
    },
    getNWPCategory(state) {
      return state.NWPCategory;
    },
    getNWPEligible(state) {
      return state.NWPEligible;
    },
    getNWPStatus(state) {
      return state.NWPStatus;
    },
    getNWPType(state) {
      return state.NWPType;
    },
    getYesNo(state) {
      return state.YesNo;
    },
    getUsedEvaluationSoftwareNWP(state) {
      return state.UsedEvaluationSoftwareNWP;
    },
    getStadiumSportfield(state) {
      return state.StadiumSportfield;
    },
    getTimeMeasure(state) {
      return state.TimeMeasure;
    },
    getContactAddressType(state) {
      return state.ContactAddressType;
    },
    getLanguage(state) {
      return state.Language;
    },
    getCorrespondenceLanguage(state) {
      return state.CorrespondenceLanguage;
    },
    getNwpDisciplineCategory(state) {
      return state.NwpDisciplineCategory;
    },
    getYoungestCategory(state) {
      return state.YoungestCategory;
    },
    getQualificationsround(state) {
      return state.Qualificationsround;
    },
    getNwpHomologation(state) {
      return state.NwpHomologation;
    },
    getSex(state) {
      return state.Sex;
    },
    getEmailType(state) {
      return state.EmailType;
    },
    getMedalType(state) {
      return state.MedalType;
    },
    getNWPQualificationForNextStage(state) {
      return state.NWPQualificationForNextStage;
    },
    getNWPMaterialMedalsInterest(state) {
      return state.NWPMaterialMedalsInterest;
    },
  },
  actions: {
    getCantonList({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "Canton",
            },
          })
          .then((resp) => {
            const cantonList = resp.data;
            commit("setCantonList", { list: cantonList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNWPCategory({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NWPCategory",
            },
          })
          .then((resp) => {
            const NWPCategoryList = resp.data;
            commit("setNWPCategory", { list: NWPCategoryList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNWPEligible({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NWPEligible",
            },
          })
          .then((resp) => {
            const NWPEligibleList = resp.data;
            commit("setNWPEligible", { list: NWPEligibleList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNWPStatus({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NWPStatus",
            },
          })
          .then((resp) => {
            const NWPStatusList = resp.data;
            commit("setNWPStatus", { list: NWPStatusList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNWPType({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NWPType",
            },
          })
          .then((resp) => {
            const NWPTypeList = resp.data;
            commit("setNWPType", { list: NWPTypeList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getYesNo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "YesNo",
            },
          })
          .then((resp) => {
            const YesNoList = resp.data;
            commit("setYesNo", { list: YesNoList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUsedEvaluationSoftwareNWP({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "UsedEvaluationSoftwareNWP",
            },
          })
          .then((resp) => {
            const UsedEvaluationSoftwareNWPList = resp.data;
            commit("setUsedEvaluationSoftwareNWP", {
              list: UsedEvaluationSoftwareNWPList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getStadiumSportfield({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "StadiumSportfield",
            },
          })
          .then((resp) => {
            const StadiumSportfieldList = resp.data;
            commit("setStadiumSportfield", { list: StadiumSportfieldList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTimeMeasure({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "TimeMeasure",
            },
          })
          .then((resp) => {
            const TimeMeasureList = resp.data;
            commit("setTimeMeasure", { list: TimeMeasureList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getContactAddressType({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "ContactAddressType",
            },
          })
          .then((resp) => {
            const ContactAddressTypeList = resp.data;
            commit("setContactAddressType", { list: ContactAddressTypeList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getLanguage({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "Language",
            },
          })
          .then((resp) => {
            const LanguageList = resp.data;
            commit("setLanguage", {
              list: LanguageList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCorrespondenceLanguage({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "CorrespondenceLanguage",
            },
          })
          .then((resp) => {
            const CorrespondenceLanguageList = resp.data;
            commit("setCorrespondenceLanguage", {
              list: CorrespondenceLanguageList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNwpDisciplineCategory({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NwpDisciplineCategory",
            },
          })
          .then((resp) => {
            const NwpDisciplineCategoryList = resp.data;
            commit("setNwpDisciplineCategory", {
              list: NwpDisciplineCategoryList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getYoungestCategory({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "YoungestCategory",
            },
          })
          .then((resp) => {
            const YoungestCategoryList = resp.data;
            commit("setYoungestCategory", {
              list: YoungestCategoryList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getQualificationsround({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "Qualificationsround",
            },
          })
          .then((resp) => {
            const QualificationsroundList = resp.data;
            commit("setQualificationsround", {
              list: QualificationsroundList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNwpHomologation({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NwpHomologation",
            },
          })
          .then((resp) => {
            const NwpHomologationList = resp.data;
            commit("setNwpHomologation", {
              list: NwpHomologationList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getSex({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "Sex",
            },
          })
          .then((resp) => {
            const SexList = resp.data;
            commit("setSex", {
              list: SexList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getEmailType({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "EmailType",
            },
          })
          .then((resp) => {
            const EmailTypeList = resp.data;
            commit("setEmailType", {
              list: EmailTypeList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getMedalType({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "MedalType",
            },
          })
          .then((resp) => {
            const MedalTypeList = resp.data;
            commit("setMedalType", {
              list: MedalTypeList,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNWPQualificationForNextStage({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NWPQualificationForNextStage",
            },
          })
          .then((resp) => {
            const NWPQualificationForNextStage = resp.data;
            commit("setNWPQualificationForNextStage", {
              list: NWPQualificationForNextStage,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNWPMaterialMedalsInterest({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getLov.php", {
            params: {
              typeCode: "NWPMaterialMedalsInterest",
            },
          })
          .then((resp) => {
            const NWPMaterialMedalsInterest = resp.data;
            commit("setNWPMaterialMedalsInterest", {
              list: NWPMaterialMedalsInterest,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setCantonList(state, payload) {
      state.cantonList = payload.list;
    },
    setNWPCategory(state, payload) {
      state.NWPCategory = payload.list;
    },
    setNWPEligible(state, payload) {
      state.NWPEligible = payload.list;
    },
    setNWPStatus(state, payload) {
      state.NWPStatus = payload.list;
    },
    setNWPType(state, payload) {
      state.NWPType = payload.list;
    },
    setYesNo(state, payload) {
      state.YesNo = payload.list;
    },
    setUsedEvaluationSoftwareNWP(state, payload) {
      state.UsedEvaluationSoftware = payload.list;
    },
    setStadiumSportfield(state, payload) {
      state.StadiumSportfield = payload.list;
    },
    setTimeMeasure(state, payload) {
      state.TimeMeasure = payload.list;
    },
    setContactAddressType(state, payload) {
      state.ContactAddressType = payload.list;
    },
    setLanguage(state, payload) {
      state.Language = payload.list;
    },
    setCorrespondenceLanguage(state, payload) {
      state.CorrespondenceLanguage = payload.list;
    },
    setNwpDisciplineCategory(state, payload) {
      state.NwpDisciplineCategory = payload.list;
    },
    setYoungestCategory(state, payload) {
      state.YoungestCategory = payload.list;
    },
    setQualificationsround(state, payload) {
      state.Qualificationsround = payload.list;
    },
    setNwpHomologation(state, payload) {
      state.NwpHomologation = payload.list;
    },
    setSex(state, payload) {
      state.Sex = payload.list;
    },
    setEmailType(state, payload) {
      state.EmailType = payload.list;
    },
    setMedalType(state, payload) {
      state.MedalType = payload.list;
    },
    setNWPQualificationForNextStage(state, payload) {
      state.NWPQualificationForNextStage = payload.list;
    },
    setNWPMaterialMedalsInterest(state, payload) {
      state.NWPMaterialMedalsInterest = payload.list;
    },
  },
};