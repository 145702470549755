import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    events: [],
  }),
  getters: {
    getEvents: (state) => {
      return state.events;
    },
  },
  actions: {
    setWettkampf({ commit }, data) {
      // Lösche alle leeren properties aus data welche 'null', 'undefinied' oder '' (leerer String) sind
      var wettkampfData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) => v !== null && v !== undefined && v !== ""
        )
      );
      return new Promise((resolve, reject) => {
        axios
          .post("/setWettkampf.php", wettkampfData)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getWettkampfMaterial({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getNWPMaterials.php", {
            params: data,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getListe({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getEvents.php", {
            params: data,
          })
          .then((resp) => {
            const events = resp.data;
            commit("setEvents", { events: events });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setTeilnehmer({ commit }, data) {
      // Lösche alle leeren properties aus data welche 'null', 'undefinied' oder '' (leerer String) sind
      var teilnehmerData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) => v !== null && v !== undefined && v !== ""
        )
      );
      return new Promise((resolve, reject) => {
        axios
          .post("/setWettkampfTeilnehmer.php", teilnehmerData)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTeilnehmer({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/getWettkampfTeilnehmer.php", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getResultateListe({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getResultList.php", {
            params: data,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getResultate({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getResults.php", {
            params: {
              nwpId: id,
            }
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getBestlistCategories({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getBestlistCategories.php")
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getBestlist({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getBestlist.php", {
            params: data
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setEvents(state, payload) {
      state.events = payload.events;
    },
  },
};
